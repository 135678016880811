import './HeaderCategoriasBlog.css'
import { NavLink } from 'react-router-dom';
import { useContext } from 'react'
import { ElectrolegDatosContext } from '../../../Context'
import icon_control_y_fuerza from '../../../img/icon_control_y_fuerza.svg'
import icon_conductores from '../../../img/icon_conductores.svg'
import icon_media_tension from '../../../img/icon_control_y_fuerza.svg'
import icon_baja_tension from '../../../img/icon_baja_tension.svg'
import icon_iluminacion from '../../../img/icon_iluminacion.svg'
import icon_herramientas from '../../../img/icon_herramientas.svg'


 const HeaderCategoriasBlog = () => {
    const { setCategoria, setSubCategoria, categoria } = useContext(ElectrolegDatosContext)

    return(
        <div>
            <div className='headerCategoriasBlog_Movile'>
            </div>
            <div className='headerCategoriasBlog_Desktop'>
                <div className='HeaderCategoriasBlog_Categorias'>
                    <ul>
                        <li className={categoria === 'Industrial' ? 'active' : ''}>
                            <NavLink to='/' onClick={() => {
                                setCategoria('Industrial');
                                setSubCategoria('Breakers');
                            }}>
                                <img src={icon_control_y_fuerza} alt='Control y Fuerza' width='27px' height='27px'/>
                                Control Y Fuerza
                            </NavLink>
                        </li>
                        <li className={categoria === 'Conductores eléctricos' ? 'active' : ''}>
                            <NavLink to='/' onClick={() => {
                                setCategoria('Conductores eléctricos');
                                setSubCategoria('Cables  Cobre de B/T');
                            }}>
                                <img src={icon_conductores} alt='Control y Fuerza' width='27px' height='27px'/>
                                Conductores
                            </NavLink>
                        </li>
                        <li className={categoria === 'Media Tensión' ? 'active' : ''}>
                            <NavLink to='/' onClick={() => {
                                setCategoria('Media Tensión');
                                setSubCategoria('Terminaciones');
                            }}>
                                <img src={icon_media_tension} alt='Control y Fuerza' width='27px' height='27px'/>
                                Media Tensión
                            </NavLink>
                        </li>
                        <li className={categoria === 'Baja Tensión' ? 'active' : ''}>
                            <NavLink to='/' onClick={() => {
                                setCategoria('Baja Tensión');
                                setSubCategoria('Herrajes');
                            }}>
                                <img src={icon_baja_tension} alt='Control y Fuerza' width='27px' height='27px'/>
                                Baja Tensión
                            </NavLink>
                        </li>
                        <li className={categoria === 'Iluminación' ? 'active' : ''}>
                            <NavLink to='/' onClick={() => {
                                setCategoria('Iluminación');
                                setSubCategoria('Artículos');
                            }}>
                                <img src={icon_iluminacion} alt='Control y Fuerza' width='27px' height='27px'/>
                                Iluminación
                            </NavLink>
                        </li>
                        <li className={categoria === 'Herramientas' ? 'active' : ''}>
                            <NavLink to='/' onClick={() => {
                                setCategoria('Herramientas');
                                setSubCategoria('Herram. Man Sujecc');
                            }}>
                                <img src={icon_herramientas} alt='Control y Fuerza' width='27px' height='27px'/>
                                Herramientas
                            </NavLink>
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>
    )
 }
 export default HeaderCategoriasBlog