import '../pages.css'
import ImgGenerator from '../../Components/ImgGenerator'
const Eventos = () =>{
    return(
        <div className='pages_body'>
            Eventos
            <ImgGenerator />
        </div>
    )
}
export default Eventos