import { useContext } from 'react';
import { ElectrolegDatosContext } from '../../../Context';
import './Subcategorias.css';

const Subcategorias = () => {
    const { productos, categoria, subCategoria, setSubCategoria } = useContext(ElectrolegDatosContext);

    // Agrupamos productos por Linea (categoría) y Grupo (subcategoría) en orden alfabético.
    const categoriasYSubcategorias = productos.reduce((acc, producto) => {
        if (!acc[producto.Linea]) acc[producto.Linea] = new Set();
        acc[producto.Linea].add(producto.Grupo);
        return acc;
    }, {});

    // Obtenemos las categorías y las ordenamos alfabéticamente
    const menuItems = Object.keys(categoriasYSubcategorias)
        .sort() // Ordena las categorías alfabéticamente
        .map(linea => ({
            categoria: linea,
            subcategorias: Array.from(categoriasYSubcategorias[linea]).sort() // Ordena las subcategorías alfabéticamente
        }));

    // Seleccionamos el item de categoría correspondiente a la categoría seleccionada
    const itemSeleccionado = menuItems.find(item => item.categoria === categoria);

    return (
        <div className="menu-lateral">
            {itemSeleccionado ? (
                <ul>
                    {itemSeleccionado.subcategorias.map(sub => (
                        <li key={sub} 
                            onClick={() => setSubCategoria(sub)}
                            className={sub === subCategoria ? 'active' : ''}
                        >
                            {sub}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No se encontraron subcategorías para la categoría seleccionada.</p>
            )}
        </div>
    );
};

export default Subcategorias;
