import './App.css';
import Header from '../../Components/Header'
import Footer from '../../Components/Footer';
import { useRoutes, BrowserRouter } from 'react-router-dom'
import Home from '../Home'
import Blog from '../Blog'
import CrearCuenta from '../CrearCuenta'
import Eventos from '../Eventos'
import InicioSesion from '../InicioSesion'
import MiCuenta from '../MiCuenta'
import MiPedido from '../MiPedido'
import MisFacturas from '../MisFacturas'
import NoFunciona from '../NoFunciona'
import NuestraEmpresa from '../NuestraEmpresa'
import Proyectos from "../Proyectos"
import TerminosCondiciones from "../TerminosCondiciones"
import PoliticasDePrivacidad from "../PoliticasDePrivacidad"
import NuestrasMarcas from "../NuestrasMarcas"
import ElectrolegMatriz from "../Sucursales/ElectrolegMatriz"
import ElectrolegCentro from "../Sucursales/ElectrolegCentro"
import ElectrolegNorte from "../Sucursales/ElectrolegNorte"
import ElectrolegViaDaule from "../Sucursales/ElectrolegViaDaule"
import ElectrolegQuito from "../Sucursales/ElectrolegQuito"
import ProductoPage from "../ProductoPage"
import SchneiderElectric from "../NuestrasMarcas/SchneiderElectric"
import ChatGPT from "../ChatGPT"
import { ElectrolegDatosProvider } from '../../Context';

const LinksRoutes = () => {
  let routes = useRoutes([
      {path:'/', element: <Home />},
      {path:'/blog', element: <Blog />},
      {path:'/crear-cuenta', element:<CrearCuenta />},
      {path:'/eventos', element: <Eventos />},
      {path: '/inicio-sesion', element: <InicioSesion />},
      {path: '/mi-cuenta', element: <MiCuenta />},
      {path: '/mi-pedido', element: <MiPedido />},
      {path: 'mis-facturas', element: <MisFacturas />},
      {path: '/*', element: <NoFunciona />},
      {path:'/nuestra-empresa', element: <NuestraEmpresa />},
      {path:'/proyectos', element: <Proyectos />},
      {path:'/terminos-condiciones', element: <TerminosCondiciones />},
      {path:'/politicas-privacidad', element: <PoliticasDePrivacidad />},
      {path:'/nuestras-marcas', element: <NuestrasMarcas />},
      {path:'/electroleg-matriz', element: <ElectrolegMatriz />},
      {path:'/electroleg-centro', element: <ElectrolegCentro />},
      {path: '/electroleg-norte', element: <ElectrolegNorte />},
      {path:'/electroleg-via-daule', element: <ElectrolegViaDaule />},
      {path:'/electroleg-quito', element: <ElectrolegQuito />},
      {path:'/producto-page', element: <ProductoPage />},
      {path:'/schneiderElectric', element: <SchneiderElectric />},
      {path:'/chat', element: <ChatGPT />},
  ])
  return routes
}
function App() {
  console.log('Prueba de carga de la variable de entorno es:', process.env.REACT_APP_MAX_LOGIN_ATTEMPTS)
  return (
    <ElectrolegDatosProvider>
      <BrowserRouter>
        <LinksRoutes  />
        <div className="App">
          <div className='App-Header'>
            <Header />
          </div>
          <div>
            <Footer />
          </div>
          
        </div>
      </BrowserRouter>
    </ElectrolegDatosProvider>
    
  );
}

export default App;
