import React from 'react';
import PropTypes from 'prop-types';
import '../ChatGPT.css';

const MessageList = ({ messages, handleImageClick, handleAddToCotizacion, scrollToBottom, chatEndRef }) => {
  return (
    <div className="msg-container">
      {messages.map((msg, index) => (
        <div key={index} className={`msg-item ${msg.sender === 'user' ? 'msg-item-user' : ''}`}>
          <div className={`msg-content ${msg.sender === 'user' ? 'msg-content-user' : ''}`}>
            <p className="msg-text"><strong>{msg.sender}:</strong></p>
            {msg.image ? (
              <img 
                src={msg.image} 
                alt="uploaded" 
                className="msg-image" 
                onClick={() => handleImageClick(index)}
                onLoad={scrollToBottom}
              />
            ) : (
              <p className="msg-text">{msg.text}</p>
            )}
            {msg.products && (
              <div className="product-list">
                {msg.products.map((product, i) => (
                  <button key={i} onClick={() => handleAddToCotizacion(product)} className="product-button">
                    {product.Codigo} - {product.Descripcion}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
      <div ref={chatEndRef} />
    </div>
  );
};

MessageList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      sender: PropTypes.string.isRequired,
      text: PropTypes.string,
      image: PropTypes.string,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          Codigo: PropTypes.string.isRequired,
          Descripcion: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  handleImageClick: PropTypes.func.isRequired,
  handleAddToCotizacion: PropTypes.func.isRequired,
  scrollToBottom: PropTypes.func.isRequired,
  chatEndRef: PropTypes.object.isRequired,
};

export default MessageList;
