import React from 'react';
import './SchneiderElectric.css';

const SchneiderElectric = () => {
    // subcategorias Son las subcategorias de la marca Scneider Electric
    const subcategorias = [
        { id: 1, name: 'Celdas', image: '/img/subCat-Celdas.jpg' },
        { id: 2, name: 'Auxiliares de automatización', image: '/img/subCat-AuxiliaresDeAutomatizacion.jpg' },
        { id: 3, name: 'Relés de media tensión (Sepam)', image: '/img/subCat-RelesDeMediaTension.jpg' },
        { id: 4, name: 'Comunicación', image: '/img/subCat-Comunicaciones.jpg' },
        { id: 5, name: 'Breakers', image: '/img/subCat-Breakers.jpg' },
        { id: 6, name: 'Arrancadores', image: '/img/subCat-Arrancadores.jpg' },
        { id: 7, name: 'Medición', image: '/img/subCat-Medicion.jpg' },
        { id: 8, name: 'Transferencias', image: '/img/subCat-Transferencias.jpg' }, 
        { id: 9, name: 'Controladores', image: '/img/subCat-Controladores.jpg' }, 
        { id: 10, name: 'Calidad de la energía', image: '/img/subCat-CalidadDeLaEnergia.jpg' },
        { id: 11, name: 'Accionamiento y motores', image: '/img/subCat-AccionamientoYMotores.jpg' },        
        { id: 12, name: 'Control y maniobra', image: '/img/subCat-ControlYManiobra.jpg' },
        { id: 13, name: 'Protecciones', image: '/img/subCat-Protecciones.jpg' },
        { id: 14, name: 'Boneras', image: '/img/subCat-Borneras.jpg' },
        { id: 15, name: 'Balizas y Columnas', image: '/img/subCat-BalizasYColumnas.jpg' },
        { id: 16, name: 'Pulsadores', image: '/img/subCat-Pulsadores.jpg' },
        { id: 17, name: 'Guardamotores', image: '/img/subCat-Guardamotores.jpg' },
        { id: 18, name: 'Selectores', image: '/img/subCat-Selectores.jpg' },
        { id: 19, name: 'Canalización', image: '/img/subCat-Canalizacion.jpg' },
        { id: 21, name: 'Detectores', image: '/img/subCat-Sensores.jpg' },
        
    ];

    return(
        <div className="marcas">
            <header className="marcas-cabecera">
                <img src="/img/Schneider_Cabecera.jpg" alt="Banner"/>
            </header>
            <div className="marcas-contenedor-subcategorias">
                {subcategorias.map((subcategory) => (
                    <div key={subcategory.id} className="marcas-subcategorias">
                        <img src={subcategory.image} alt={subcategory.name} className="marcas-subcategorias-image" />
                        <p>{subcategory.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default SchneiderElectric;