import React, { createContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import data from '../json_productos.json'; // KLEBER ESTO ES PROVISIONAL HASTA QUE SOLUCIONES LA FUNCION QUE LLAMA AL API

export const ElectrolegDatosContext = createContext();

export const ElectrolegDatosProvider = ({children}) => {
    const [contadorCarrito, setContadorCarrito] = useState(0);
    const [sumaValorProductos, setSumaValorProductos] = useState(0);
    const [dataProducto, setDataProducto] = useState(null);
    const [carritoProductos, setCarritoProductos] = useState([]);
    const [busquedaProductos, setBusquedaProductos] = useState('');
    const [productosBuscados, setProductosBuscados] = useState([]);
    const [categoria, setCategoria] = useState('Iluminación');
    const [subCategoria, setSubCategoria] = useState('Reflectores');
    const [marcasProductos, setMarcasProductos] = useState([]);
    const [productos, setProductos] = useState([]);
    const [productosMinPVP, setProductosMinPVP] = useState(0); // valor mínimo de precio
    const [productosMaxPVP, setProductosMaxPVP] = useState(0); // valor máximo de precio
    const [seleccionadoMinPVP, setSeleccionadoMinPVP] = useState(0);
    const [seleccionadoMaxPVP, setSeleccionadoMaxPVP] = useState(0);
    const [marcaSeleccionada, setMarcaSeleccionada] = useState('');
    const [productosSinImagen, setProductosSinImagen] = useState([]);

    // Cargar productos desde un archivo JSON
    useEffect(() => {
        const cargarProductos = async () => {
            // Simular la carga de un archivo JSON
            const fetchedProductos = await Promise.resolve(Object.values(data));
            setProductos(fetchedProductos);
        };
        
        cargarProductos();
    }, []);

    // Filtrar productos basados en búsqueda de productos
    useEffect(() => {
        if (busquedaProductos) {
            const filtrados = productos.filter(producto =>
                producto.ItemName.toLowerCase().includes(busquedaProductos.toLowerCase())
            );
            setProductosBuscados(filtrados);
        }
    }, [productos, busquedaProductos]);

    // Filtrar productos basados en categoría y subcategoría seleccionadas
    useEffect(() => {
        let filtrados = productos;
    
        if (categoria) {
            filtrados = filtrados.filter(producto => producto.Linea === categoria);
        }
    
        if (subCategoria) {
            filtrados = filtrados.filter(producto => producto.Grupo === subCategoria);
        }
    
        if (marcasProductos && marcasProductos.length > 0) {
            filtrados = filtrados.filter(producto => marcasProductos.includes(producto.Marca));
        }
    
        setProductosBuscados(filtrados);
    }, [productos, categoria, subCategoria, marcasProductos]);

    const agregarProductoSinImagen = (itemCode) => {
        setProductosSinImagen((prevState) => [...prevState, itemCode]);
    };

    const descargarProductosSinImagen = () => {
        const element = document.createElement('a');
        const file = new Blob([JSON.stringify(productosSinImagen)], { type: 'application/json' });
        element.href = URL.createObjectURL(file);
        element.download = 'productos_sin_imagen.json';
        document.body.appendChild(element);
        element.click();
    };

    return (
        <ElectrolegDatosContext.Provider value={{
            contadorCarrito,
            setContadorCarrito,
            sumaValorProductos,
            setSumaValorProductos,
            dataProducto,
            setDataProducto,
            carritoProductos,
            setCarritoProductos,
            productos,
            setProductos,
            busquedaProductos,
            setBusquedaProductos,
            productosBuscados,
            setProductosBuscados,
            categoria,
            setCategoria,
            subCategoria,
            setSubCategoria,
            marcasProductos,
            setMarcasProductos,
            productosMinPVP, 
            setProductosMinPVP,
            productosMaxPVP, 
            setProductosMaxPVP,
            seleccionadoMinPVP,
            setSeleccionadoMinPVP,
            seleccionadoMaxPVP,
            setSeleccionadoMaxPVP,
            marcaSeleccionada,
            setMarcaSeleccionada,
            productosSinImagen,
            agregarProductoSinImagen,
            descargarProductosSinImagen
        }}>
            {children}
        </ElectrolegDatosContext.Provider>
    );
};

ElectrolegDatosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
