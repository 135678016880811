import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ElectrolegDatosContext } from '../../Context';
import ProductNavigation from '../../Components/ProductNavigation';
import icon_Whatsapp from '../../img/icon_whatsapp_footer.svg';
import '../pages.css';
import './ProductoPage.css';

const ProductoPage = () => {
    const navigate = useNavigate();
    const { dataProducto } = useContext(ElectrolegDatosContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!dataProducto) {
            navigate('/');
        } else {
            setIsLoading(false);
        }
    }, [dataProducto, navigate]);

    if (isLoading) return <div>Cargando...</div>;

    const linkWhatsapp = (nombre, id) => {
        return `https://api.whatsapp.com/send?phone=593991875332&text=Saludos%20Electroleg,%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20producto%20${nombre},%20con%20el%20c%C3%B3digo%20${id}.%20Muchas%20gracias`;
    };

    return (
        <div className='pages_body'>
            <div className='producto-page-container'>
                <div className='product-navigation'>
                    <ProductNavigation producto={dataProducto} />
                </div>
                <div className='producto-detalle'>
                    <div className='producto-imagen'>
                        <img
                            src={`/productos/500x500/${dataProducto.ItemCode}.jpg`}
                            alt={dataProducto.ItemName}
                            onError={(e) => {
                                e.target.src = '/productos/500x500/proximamente.jpg';
                            }}
                        />
                    </div>
                    <div className='producto-info'>
                        <h1>{dataProducto.ItemName}</h1>
                        <p>Código: {dataProducto.ItemCode}</p>
                        {dataProducto.Marca && <p>Marca: {dataProducto.Marca}</p>}
                        {dataProducto.Linea && <p>Categoría: {dataProducto.Linea}</p>}
                        {dataProducto.Grupo && <p>Subcategoría: {dataProducto.Grupo}</p>}
                        
                        <div className='producto-whatsapp'>
                            <a 
                                href={linkWhatsapp(dataProducto.ItemName, dataProducto.ItemCode)}
                                target='_blank'
                                rel="noopener noreferrer"
                                className='whatsapp-button'
                            >
                                <img src={icon_Whatsapp} alt="WhatsApp" />
                                Solicitar más información
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
};

export default ProductoPage;
