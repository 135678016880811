import './Header.css'
import HeaderLogotipo from './HeaderLogotipo'
import HeaderBusqueda from './HeaderBusqueda'
import HeaderLoginCarrito from './HeaderLoginCarrito'
import HeaderCategoriasBlog from './HeaderCategoriasBlog'     
const Header = () => {

    return(
        <div className='header'>
            <div className='header_Sup'>
                <div>
                    <HeaderLogotipo />
                </div>            
                <div>
                    <HeaderBusqueda />
                </div>
                <div>
                    <div>
                        <HeaderLoginCarrito/>
                    </div>
                </div>
            </div>
            <div>
                <HeaderCategoriasBlog />
            </div>
        </div>     
    )
}
export default Header