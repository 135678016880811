import Subcategorias from './Subcategorias'
import MarcasCheckbox from './MarcasCheckbox';
//import FiltroPrecio from './FiltroPrecio';
import './MenuLateral.css';

const MenuLateral = () => {
   
    return (
        <div>
            <Subcategorias />
            <MarcasCheckbox />
        </div>
    );
};

export default MenuLateral;
