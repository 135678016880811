import React, { useRef, useState, useEffect } from "react";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import productos from '../../data/productos.json'; // Ajustar la ruta según sea necesario
import './ImgGenerator.css'; // Archivo CSS para estilos

const ImgGenerator = () => {
  const canvasRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const totalImages = productos.length * 2; // Asumimos que cada producto tiene 2 imágenes
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const startTimeRef = useRef(Date.now());
  const [failedImages, setFailedImages] = useState([]);
  const [failedProducts, setFailedProducts] = useState([]);

  const checkImagesLoaded = () => {
    const images = document.querySelectorAll('.layer1, .layer2');
    const loadedImages = Array.from(images).filter(img => img.complete && img.naturalWidth !== 0).length;
    setImagesLoaded(loadedImages);
    const allLoaded = loadedImages === totalImages;
    setAllImagesLoaded(allLoaded);
    if (allLoaded) {
      console.log('All images are loaded');
    } else {
      console.log(`Images loaded: ${loadedImages} of ${totalImages}`);
    }
  };

  const handleImageError = (src, product) => {
    setFailedImages(prev => [...prev, src]);
    setFailedProducts(prev => [...prev, product]);
    console.error(`Failed to load image: ${src}`);
  };

  useEffect(() => {
    const calculateRemainingTime = () => {
      const images = document.querySelectorAll('.layer1, .layer2');
      const loadedImages = Array.from(images).filter(img => img.complete && img.naturalWidth !== 0).length;
      if (loadedImages > 0) {
        const elapsedTime = (Date.now() - startTimeRef.current) / 1000; // Convert to seconds
        const averageTimePerImage = elapsedTime / loadedImages;
        const remainingImages = totalImages - loadedImages;
        const estimatedRemainingTime = Math.round(averageTimePerImage * remainingImages);
        console.log(`Estimated remaining time: ${estimatedRemainingTime} seconds`);
      }
    };

    const interval = setInterval(() => {
      if (!allImagesLoaded) {
        calculateRemainingTime();
      }
    }, 1000);

    window.addEventListener('load', checkImagesLoaded);
    window.addEventListener('scroll', checkImagesLoaded);

    return () => {
      clearInterval(interval);
      window.removeEventListener('load', checkImagesLoaded);
      window.removeEventListener('scroll', checkImagesLoaded);
    };
  }, [allImagesLoaded, totalImages]);

  const getLineaImage = (linea) => {
    switch (linea) {
      case 'Industrial':
        return 'ControlYFuerza.png';
      case 'Iluminación':
        return 'Iluminacion.png';
      case 'Media Tensión':
        return 'MediaTension.png';
      case 'Herramientas':
        return 'Herramientas.png';
        case 'Baja Tensión':
        return 'BajaTension.png';
      default:
        return 'default.png';
    }
  };

  const downloadBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const downloadDivAsImage = async (index) => {
    const canvasElement = canvasRefs.current[index];
    if (!canvasElement) {
      console.error(`Element with index ${index} not found`);
      return;
    }

    try {
      canvasElement.classList.add('highlight'); // Add highlight class
      await new Promise(resolve => setTimeout(resolve, 500)); // Agregar un retraso para asegurar la renderización completa
      const canvasImage = await html2canvas(canvasElement, {
        useCORS: true,
        scale: 2,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY
      });
      const imgBlob = await new Promise(resolve => canvasImage.toBlob(resolve, 'image/jpeg'));
      if (imgBlob) {
        downloadBlob(imgBlob, `${productos[index].CodSAP}.jpg`);
        console.log(`Downloaded ${productos[index].CodSAP}.jpg successfully`);
      } else {
        console.error(`Invalid image data for ${productos[index].CodSAP}`);
      }
      canvasElement.classList.remove('highlight'); // Remove highlight class
    } catch (error) {
      console.error(`Error capturing image for ${productos[index].CodSAP}:`, error);
    }
  };

  const createCanvas = async () => {
    setLoading(true);
    console.log('Starting canvas creation process...');

    const zip = new JSZip();

    const canvasPromises = productos.map(async (product, i) => {
      const canvasElement = canvasRefs.current[i];
      try {
        await new Promise(resolve => setTimeout(resolve, 500)); // Asegurar que el DOM esté completamente renderizado
        const canvasImage = await html2canvas(canvasElement, {
          useCORS: true,
          scale: 2,
          logging: true, // Enable logging to see more details
          scrollX: -window.scrollX,
          scrollY: -window.scrollY
        });
        const imgBlob = await new Promise(resolve => canvasImage.toBlob(resolve, 'image/jpeg'));
        console.log(`Captured canvas for ${product.CodSAP}`, imgBlob); // Verificar el contenido del canvas capturado

        if (imgBlob) {
          zip.file(`${product.CodSAP}.jpg`, imgBlob);
          console.log(`Added ${product.CodSAP}.jpg to zip`);
        } else {
          console.error(`Invalid image data for ${product.CodSAP}`);
        }
      } catch (error) {
        console.error(`Error creating canvas for ${product.CodSAP}:`, error);
      }
    });

    await Promise.all(canvasPromises);

    try {
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "productos.zip");
      console.log("ZIP file created and downloaded successfully");
    } catch (error) {
      console.error('Error generating zip file:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {productos.map((product, index) => (
        <div key={product.CodSAP} className="product-container">
          <div ref={el => (canvasRefs.current[index] = el)} className="product-canvas">
            <img src={`/path/to/ImgPrueba/${product.Imagen}`} alt={product.Nombre} className="layer1" onLoad={checkImagesLoaded} onError={() => handleImageError(product.Imagen, product)} />
            <img
              src={`/path/to/cabecera/${getLineaImage(product.Linea)}`}
              alt={product.Linea}
              className="layer2"
              onLoad={checkImagesLoaded}
              onError={() => handleImageError(getLineaImage(product.Linea), product)}
            />
            <div className="layer3"><b>{product.CodSAP}</b></div>
            <div className="layer4"># de parte: <b>{product.NumParte}</b></div>
            <div className="layer5">{product.Nombre}</div>
          </div>
          <button 
            onMouseOver={() => canvasRefs.current[index].classList.add('highlight')}
            onMouseOut={() => canvasRefs.current[index].classList.remove('highlight')}
            onClick={() => downloadDivAsImage(index)}
            className="download-button"
          >
            Download as Image
          </button>
        </div>
      ))}
      <button onClick={createCanvas} disabled={loading || !allImagesLoaded}>
        {loading ? "Generating..." : "Download All"}
      </button>
      {!allImagesLoaded && (
        <p>Se han cargado {imagesLoaded} imágenes de {totalImages} imágenes...</p>
      )}
      {failedImages.length > 0 && (
        <div>
          <p>Las siguientes imágenes no se pudieron cargar:</p>
          <ul>
            {failedImages.map((src, index) => (
              <li key={index}>{src}</li>
            ))}
          </ul>
          <p>Productos con imágenes fallidas:</p>
          <ul>
            {failedProducts.map((product, index) => (
              <li key={index}>
                CodSAP: {product.CodSAP}, Nombre: {product.Nombre}, NumParte: {product.NumParte}, Linea: {product.Linea}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ImgGenerator;
