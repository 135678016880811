import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../ChatGPT.css';

const ImageCarousel = ({ images, carouselIndex, setCarouselIndex, setCarouselOpen }) => {
  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.8)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
      <Carousel
        selectedItem={carouselIndex}
        onChange={(index) => setCarouselIndex(index)}
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        useKeyboardArrows
        autoPlay={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow carousel-arrow-left">
              &#10094;
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow carousel-arrow-right">
              &#10095;
            </button>
          )
        }
      >
        {images.map((src, index) => (
          <div key={index} className="carousel-image-container">
            <img src={src} alt={`image-${index}`} className="carousel-image" />
          </div>
        ))}
      </Carousel>
      <button onClick={() => setCarouselOpen(false)} className="carousel-close-button">×</button>
    </div>
  );
};

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  carouselIndex: PropTypes.number.isRequired,
  setCarouselIndex: PropTypes.func.isRequired,
  setCarouselOpen: PropTypes.func.isRequired,
};

export default ImageCarousel;
