import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './ProductNavigation.css';

const ProductNavigation = ({ producto }) => {
    return (
        <div className='product-navigation'>
            <div className='breadcrumb-container'>
                <nav className='breadcrumb'>
                    <Link to="/" className='breadcrumb-item'>Inicio</Link>
                    <span className='breadcrumb-separator'>/</span>
                    <Link to={`/categoria/${producto.Linea}`} className='breadcrumb-item'>
                        {producto.Linea}
                    </Link>
                    <span className='breadcrumb-separator'>/</span>
                    <Link to={`/subgrupo/${producto.Subgrupo}`} className='breadcrumb-item'>
                        {producto.Subgrupo}
                    </Link>
                    <span className='breadcrumb-separator'>/</span>
                    <Link to={`/grupo/${producto.Grupo}`} className='breadcrumb-item'>
                        {producto.Grupo}
                    </Link>
                    <span className='breadcrumb-separator'>/</span>
                    <span className='breadcrumb-item active'>{producto.ItemCode}</span>
                </nav>
            </div>
        </div>
    );
};

ProductNavigation.propTypes = {
    producto: PropTypes.shape({
        Linea: PropTypes.string.isRequired,
        Subgrupo: PropTypes.string.isRequired,
        Grupo: PropTypes.string.isRequired,
        ItemCode: PropTypes.string.isRequired,
        Marca: PropTypes.string.isRequired,
    }).isRequired,
};

export default ProductNavigation;