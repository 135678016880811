import { useContext, useEffect } from 'react';
import { ElectrolegDatosContext } from '../../../Context';
import './MarcasCheckbox.css';

const MarcasCheckbox = () => {
    const { productos, categoria, subCategoria, marcasProductos, setMarcasProductos } = useContext(ElectrolegDatosContext);

    // Filtrar productos según la categoría y subcategoría seleccionadas
    const productosFiltrados = productos.filter(producto => {
        if (categoria && subCategoria) {
            return producto.Linea === categoria && producto.Grupo === subCategoria;
        } else if (categoria) {
            return producto.Linea === categoria;
        }
        return true;
    });

    // Obtener todas las marcas únicas de los productos filtrados
    const marcasUnicas = Array.from(new Set(productosFiltrados.map(producto => producto.Marca)));

    // Actualizar las marcas seleccionadas cuando cambian la categoría o subcategoría
    useEffect(() => {
        setMarcasProductos(marcasUnicas);
    }, [categoria, subCategoria, setMarcasProductos, productos]);

    // Manejar la selección de marcas
    const handleMarcaChange = (marca) => {
        setMarcasProductos((prevMarcas) => {
            if (prevMarcas.includes(marca)) {
                // Si la marca ya está seleccionada, la eliminamos
                return prevMarcas.filter(item => item !== marca);
            } else {
                // Si la marca no está seleccionada, la agregamos
                return [...prevMarcas, marca];
            }
        });
    };

    return (
        <div className="marcas-checkbox">
            <h3>Marcas</h3>
            <ul>
                {marcasUnicas.map(marca => (
                    <li key={marca}>
                        <label>
                            <input
                                className='mycheck'
                                type="checkbox"
                                checked={marcasProductos.includes(marca)}
                                onChange={() => handleMarcaChange(marca)}
                            />
                            {marca}
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MarcasCheckbox;
