import React from 'react';
import PropTypes from 'prop-types';
import '../ChatGPT.css';
import fileIcon from '../../../img/Adjuntar_Icon_.svg';

const ChatInput = ({ input, setInput, sendMessage, handleImageChange }) => {
  return (
    <div className="envio-container">
      <label className="envio-file-button" htmlFor="file-input">
        <img src={fileIcon} alt="Choose file" />
      </label>
      <input
        id="file-input"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="envio-file-input"
      />
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => (e.key === 'Enter' ? sendMessage() : null)}
        className="envio-input"
      />
      <button onClick={sendMessage} className="envio-button">Send</button>
    </div>
  );
};

ChatInput.propTypes = {
  input: PropTypes.string.isRequired,
  setInput: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
};

export default ChatInput;
