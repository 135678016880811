import './HeaderLoginCarrito.css'
import HeaderCarritoCompras from '../HeaderCarritoCompras'
import HeaderLogin from '../HeaderLogin'
import icon_Btn_Sanduche from'../../../img/icon_Btn_Sanduche.svg'

const HeaderLoginCarrito= () => {
    return(
        <div>
            <div className='HeaderLoginCarritoMovile'>
                <div>
                    <img src={icon_Btn_Sanduche} alt='Boton' width='20px' height='20px'/>
                </div>
            </div>
            <div className='HeaderLoginCarritoDesktop'>
                <div>
                    <HeaderLogin />
                </div>
                <div>
                    <HeaderCarritoCompras />
                </div>
            </div>
        </div>
    )
}
export default HeaderLoginCarrito