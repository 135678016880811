import React from 'react';
import ChatGPT from '../../Components/ChatGPT';
import '../pages.css';

const SchneiderElectric = () => {
   
    
    return(
        <div className='pages_body'>
            <ChatGPT />
        </div>
    );
};
export default SchneiderElectric;