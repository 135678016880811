import '../pages.css';
import './Home.css';
import { useContext } from 'react';
import { ElectrolegDatosContext } from '../../Context';
import Producto from '../../Components/Catalogo/Producto';
import MenuLateral from '../../Components/MenuLateral';

const Home = () => {
    const { productos, productosBuscados, busquedaProductos, categoria, subCategoria, marcasProductos, agregarProductoSinImagen, descargarProductosSinImagen } = useContext(ElectrolegDatosContext);

    // Visualiza los productos dependiendo de la búsqueda, categoría, subcategoría y marcas
    const renderProductos = () => {
        let productosFiltrados = productos || []; // Asegúrate de inicializar productosFiltrados como una matriz vacía si productos es undefined

        // Filtrar productos según la búsqueda
        if (busquedaProductos?.length > 0) {
            productosFiltrados = productosBuscados.length > 0 ? productosBuscados : [];
        }

        // Filtrar productos según la subcategoría seleccionada
        if (subCategoria) {
            productosFiltrados = productosFiltrados.filter(producto => 
                producto.Grupo === subCategoria && producto.Linea === categoria
            );
        } else if (categoria) {
            // Filtrar productos según la categoría seleccionada
            productosFiltrados = productosFiltrados.filter(producto => producto.Linea === categoria);
        }

        // Filtrar productos según las marcas seleccionadas
        if (marcasProductos && marcasProductos.length > 0) {
            productosFiltrados = productosFiltrados.filter(producto => marcasProductos.includes(producto.Marca));
            console.log('marcasProductos: ', marcasProductos);
        }

        // Mostrar productos filtrados o mensajes correspondientes
        if (busquedaProductos?.length > 0 && productosFiltrados.length === 0) {
            return <div>Lo sentimos. No encontramos este producto</div>;
        }

        if (subCategoria && productosFiltrados.length === 0) {
            return <div>No hay productos en esta subcategoría.</div>;
        }

        return productosFiltrados.map((producto) => (
            <Producto key={producto.ItemCode} producto={producto} agregarProductoSinImagen={agregarProductoSinImagen} />
        ));
    };

    return (
        <div className="pages_body">
            <div>
                <MenuLateral />
            </div>
            <div className="Home-Desktop">
                {renderProductos()}
                <button onClick={descargarProductosSinImagen}>Descargar productos sin imagen</button>
            </div>
        </div>
    );
};

export default Home;
