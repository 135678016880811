import './HeaderBusqueda.css'
import { useContext} from 'react'
import { ElectrolegDatosContext } from '../../../Context'
import icon_Lupa from '../../../img/icon_Lupa.svg'

const HeaderBusqueda = () => {
    const context = useContext(ElectrolegDatosContext)
    return(
        <div>
            <span className='HeaderFormulario_Movile'>
                <img src={icon_Lupa} alt='Lupa de Búsqueda' width='25px' height='25px' />
                <input type="text" placeholder="Ingrese el nombre del producto"/>
            </span>
            <span className='HeaderFormulario_Desktop'>
                Búsqueda  
                <img src={icon_Lupa} alt='Lupa de Búsqueda' width='25px' height='25px' />
                <input 
                    type="text"
                    onChange={(event) => context.setBusquedaProductos(event.target.value) }
                />
            </span>            
        </div>
    )
}

export default HeaderBusqueda