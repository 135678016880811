import { NavLink } from 'react-router-dom'
import { useContext } from 'react'
import { ElectrolegDatosContext } from '../../Context'
import { Link } from 'react-router-dom';
import './Footer.css'
import Marcas from './Marcas'
import icon_home_footer from '../../img/icon_home_footer.svg'
import icon_eventos_footer from '../../img/icon_eventos_footer.svg'
import icon_carrito_compras_footer from '../../img/icon_carrito_compras_footer.svg'
import icon_login_footer from '../../img/icon_login_footer.svg'
import icon_whatsapp_footer from '../../img/icon_whatsapp_footer.svg'
import icon_control_y_fuerza from '../../img/icon_control_y_fuerza.svg'
import icon_conductores from '../../img/icon_conductores.svg'
import icon_media_tension from '../../img/icon_control_y_fuerza.svg'
import icon_baja_tension from '../../img/icon_baja_tension.svg'
import icon_iluminacion from '../../img/icon_iluminacion.svg'
import icon_herramientas from '../../img/icon_herramientas_negro.svg'
import icon_Whatsapp from '../../img/icon_whatsapp_footer.svg'
import icon_Email from '../../img/icon_email.svg'
import icon_facebook from '../../img/icon_facebook.svg'
import icon_youtube from '../../img/icon_youtube.svg'
import icon_instagram from '../../img/icon_instagram.svg'

const Footer = () => {
    const { setCategoria, setSubCategoria } = useContext(ElectrolegDatosContext)
    return(
        <div>
            <div className='footer_movile'>
                <div className='footer_movile_carritoCompras'>
                    $ 1200 | 2 productos
                </div>
                <ul>
                    <li>
                        <img src={icon_home_footer} alt='Home' width='70%' height='70%'/>
                    </li>
                    <li>
                        <img src={icon_eventos_footer} alt='Eventos' width='70%' height='70%'/>
                    </li>
                    <li>
                        <img src={icon_carrito_compras_footer} alt='Carrito de Compras' width='70%' height='70%'/>
                    </li>
                    <li>
                        <img src={icon_login_footer} alt='Login' width='70%' height='70%'/>
                    </li>
                    <li>
                        <img src={icon_whatsapp_footer} alt='Whatsapp' width='70%' height='70%'/>
                    </li>
                </ul>
            </div>
            <div className='footer_desktop'>
                <Marcas />
                <div className='footer_'>
                    <div className='footer_franja_superior'>
                    </div>
                    <div className='footer_contenedor'>
                        <div className='footer_seccion_categoria'>
                            <ul>
                                <li className='control_y_fuerza'>
                                <NavLink to='/' onClick={() => {
                                    setCategoria('Industrial')
                                    setSubCategoria('Breakers')
                                }}>
                                <img src={icon_control_y_fuerza} alt='Control y Fuerza' width='80px' height='80px'/>
                                Control Y Fuerza
                            </NavLink>
                                </li>
                                <li className='conductores'>
                                    <NavLink to='/' onClick={() => {
                                        setCategoria('Conductores eléctricos')
                                        setSubCategoria('Cables  Cobre de B/T')
                                    }}>
                                        <img src={icon_conductores} alt='Control y Fuerza' width='80px' height='80px'/>
                                        Conductores
                                    </NavLink>
                                </li>
                                <li className='media_tension'>
                                    <NavLink to='/' onClick={() => {
                                    setCategoria('Media Tensión');
                                    setSubCategoria('Terminaciones');
                                    }}>
                                        <img src={icon_media_tension} alt='Control y Fuerza' width='80px' height='80px'/>
                                        Media Tensión
                                    </NavLink>
                                </li>
                                <li className='baja_tension'>
                                    <NavLink to='/' onClick={() => {
                                        setCategoria('Baja Tensión');
                                        setSubCategoria('Herrajes');
                                    }}>
                                        <img src={icon_baja_tension} alt='Control y Fuerza' width='80px' height='80px'/>
                                        Baja Tensión
                                    </NavLink>
                                </li>
                                <li className='iluminacion'>
                                    <NavLink to='/' onClick={() => {
                                        setCategoria('Iluminación');
                                        setSubCategoria('Artículos');
                                    }}>
                                        <img src={icon_iluminacion} alt='Control y Fuerza' width='80px' height='80px'/>
                                        Iluminación
                                    </NavLink>
                                </li>
                                <li className='herramientas'>
                                    <NavLink to='/' onClick={() => {
                                        setCategoria('Herramientas');
                                        setSubCategoria('Herram. Man Sujecc');
                                    }}>
                                        <img src={icon_herramientas} alt='Herramientas' width='80px' height='80px'/>
                                        Herramientas
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className='footer_seccion'>
                            <ul>
                                <li>
                                    <a href='https://bit.ly/4a3M4ff' target='_blank' rel="noopener noreferrer">
                                        <img src={icon_Whatsapp} alt='Whatsapp' width='32px' height='32px' />
                                        WhatsApp: <b>(09) 9187 5332</b> 
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:info@electroleg.com" rel="noopener noreferrer">
                                        <img src={icon_Email} alt='Email' width='33px' height='33px'/>
                                        <b>info@electroleg.com</b>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/electroleg" target='_blank' rel="noopener noreferrer">
                                        <img src={icon_facebook } alt='Email' width='33px' height='33px'/>
                                        Facebook: <b>/electroleg</b>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCkDJWPd_A2mfr9ALYmErVFg" target='_blank' rel="noopener noreferrer">
                                        <img src={icon_youtube} alt='Email' width='33px' height='33px'/>
                                        Youtube: <b>@electroleg</b>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/electroleg.ec/?hl=es-la" target='_blank' rel="noopener noreferrer">
                                        <img src={icon_instagram} alt='Email' width='33px' height='33px'/>
                                        Instagram: <b>@electroleg</b>
                                    </a>
                                </li>
                                <li>
                                    <Link to="/chat">Cotiza con nosotros</Link>
                                </li>
                                <li>
                                    <Link to="/eventos">Imagenes</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer