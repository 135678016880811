import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './Producto.css';
import { useNavigate } from 'react-router-dom';
import icon_Whatsapp from '../../../img/icon_whatsapp_footer.svg';
import { ElectrolegDatosContext } from '../../../Context/index.jsx'; // Ajusta la ruta según tu estructura

const Producto = ({ producto, style }) => {
    const navigate = useNavigate();
    const { setDataProducto, agregarProductoSinImagen } = useContext(ElectrolegDatosContext);

    const detalleProducto = (data_producto) => {
        setDataProducto(data_producto);
        navigate('/producto-page');
    };

    const cargarProximamente = (event) => {
        console.log(`Imagen no encontrada para el producto con ItemCode: ${producto.ItemCode}`);
        agregarProductoSinImagen(producto.ItemCode);
        event.target.src = '/productos/500x500/proximamente.jpg';
    };

    const truncaTitulo = (str, numChars = 34) => {
        if (str.length > numChars) {
            return str.substring(0, numChars) + '...';
        }
        return str;
    };

    const linkWhatsapp = (nombre, id) => {
        return `https://api.whatsapp.com/send?phone=593991875332&text=Saludos%20Electroleg,%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20producto%20${nombre},%20con%20el%20c%C3%B3digo%20${id}.%20Muchas%20gracias`;
    };

    return (
        <div>
            <div className='producto_Movile'>
                <div onClick={() => detalleProducto(producto)}>
                    {/* Contenido móvil */}
                </div>
            </div>
            <div style={style}>
                <div className='producto_Desktop'>
                    <div className='producto_Desktop-contenedor'>
                        <div onClick={() => detalleProducto(producto)}>
                            <figure className='fproducto_Desktop_figure'>
                                <img
                                    className='producto_Desktop_img'
                                    src={`/productos/500x500/${producto.ItemCode}.jpg`}
                                    alt={producto.ItemName}
                                    width='100%'
                                    height='100%'
                                    onError={cargarProximamente}
                                />
                            </figure>
                        </div>
                        <div 
                            className='producto_Desktop_nombre' 
                            onClick={() => detalleProducto(producto)}
                        >
                            {truncaTitulo(producto.ItemName)}
                            <div className='producto_Desktop_whatsapp'>
                                <a href={linkWhatsapp(producto.ItemName, producto.ItemCode)} 
                                   target='_blank' 
                                   rel="noopener noreferrer">
                                    <img src={icon_Whatsapp} 
                                         width='30px' 
                                         height='30px' 
                                         alt='Whatsapp' />
                                    Mas Información
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Producto.propTypes = {
    producto: PropTypes.shape({
        ItemCode: PropTypes.string.isRequired,
        ItemName: PropTypes.string.isRequired,
    }).isRequired,
    style: PropTypes.object,
};

export default Producto;