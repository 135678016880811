// src/Components/ChatGPT/CabeceraChatGPT.jsx
import React from 'react';
import './CabeceraChatGPT.css';
import Avatar from '../../../img/munequitos.jpg';
import WhatsAppIcon from '../../../img/Whatsapp_Icon_Logotipo_.svg';
import PhoneIcon from '../../../img/Whatsapp_Icon_Telefono_.svg';

const CabeceraChatGPT = () => {
  return (
    <div className="cabecera-container">
      <img src={Avatar} alt="Avatar" className="avatar"/>
      <div className="icons-container">
        <img src={WhatsAppIcon} alt="WhatsApp" className="icon" />
        <img src={PhoneIcon} alt="Phone" className="icon" />
      </div>
    </div>
  );
};

export default CabeceraChatGPT;
