import React from 'react';
import { Link } from 'react-router-dom';
import './Marcas.css';

const Marcas = () => {
    // subcategorias Son las subcategorias de la marca Schneider Electric
    const listaMarcas = [
        { id: 1, name: '3M', image: '/img/marca-3M.png', link: '/' },
        { id: 2, name: 'Schneider Electric', image: '/img/marca-Schneider.png', link: '/schneiderElectric' },
        { id: 3, name: 'Bticino', image: '/img/marca-bticino.png', link: '/' },
        { id: 4, name: 'Cablec', image: '/img/marca-Cablec.png', link: '/' },
        { id: 5, name: 'Centelsa', image: '/img/marca-Centelsa.png', link: '/' },
        { id: 6, name: 'Electrocables', image: '/img/marca-Electrocables.png', link: '/' },
        { id: 7, name: 'Incable', image: '/img/marca-Incable.png', link: '/' },
        { id: 8, name: 'KleinTools', image: '/img/marca-KleinTools.png', link: '/' }, 
        { id: 9, name: 'Klem', image: '/img/marca-Klem.png', link: '/' }, 
        { id: 10, name: 'Ledvance', image: '/img/marca-Ledvance.png', link: '/' },
        { id: 11, name: 'Maviju', image: '/img/marca-Maviju.png', link: '/' },
        { id: 12, name: 'Sylvania', image: '/img/marca-Sylvania.png', link: '/' },
        { id: 13, name: 'Tubos Pacificos', image: '/img/marca-TubosPacificos.png', link: '/' },
        { id: 14, name: 'Camsco', image: '/img/marca-Camsco.png', link: '/' }
    ];

    return (
        <div className="marcas-logotipo">
            {listaMarcas.map((marca) => (
                <Link key={marca.id} className="marca-caja" to={marca.link}>
                    <img src={marca.image} alt={marca.name} />
                </Link>
            ))}
        </div>
    );
};

export default Marcas;
