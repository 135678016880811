import './Header_Logotipo.css'
import { NavLink } from 'react-router-dom'
import logotipo from '../../../img/Cabecera_Logotipo_Electroleg.svg'
import logotipoResponsive from '../../../img/Cabecera_Logotipo_Responsive_Electroleg.svg'

const HeaderLogotipo = () =>{
    return(
        <div>
            <div className='Header_LogotipoMovile'>
                <NavLink to='/'>
                    <img src={logotipoResponsive} alt='Logotipo Electroleg Responsive' width='21' height='30' />
                </NavLink>
            </div>
            <div className='Header_Logotipo'>
                <NavLink to='/'>
                    <img src={logotipo} alt='Logotipo Electroleg' width='153' height='30' />
                </NavLink>
            </div>            
        </div>     
    )
}

export default HeaderLogotipo