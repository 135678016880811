import './HeaderCarritoCompras.css'
//import { NavLink } from 'react-router-dom'
//import icon_CarritoCompras from '../../../img/icon_CarritoCompras.svg'
import icon_Whatsapp from '../../../img/icon_whatsapp_footer.svg'

const HeaderCarritoCompras = () =>{
    return(
        <div className='HeaderCarritoCompras'>
            <ul>
                <li>
                    <a href='https://bit.ly/4a3M4ff' target='_blank' rel="noopener noreferrer">
                        <img src={icon_Whatsapp} alt='Whatsapp' width='32px' height='32px' />
                        Escríbenos (09) 9187 5332
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default HeaderCarritoCompras