import React, { useState, useEffect, useRef } from 'react';
import ChatInput from './ChatInput';
import MessageList from './MessageList';
import ImageCarousel from './ImageCarousel';
import CabeceraChatGPT from './CabeceraChatGPT';
import './ChatGPT.css';

const ChatGPT = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [image, setImage] = useState(null);
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    console.log('API Key:', process.env.REACT_APP_OPENAI_API_KEY); // Verificar que la API Key esté cargada
    console.log('Image: ', image);
  }, []);

  const sendMessage = () => {
    if (input.trim()) {
      const newMessage = { sender: 'user', text: input };
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      console.log('Sending message:', input);
      console.log('API Key:', process.env.REACT_APP_OPENAI_API_KEY); // Verifica que la API Key esté cargada

      const payload = {
        model: 'gpt-4o', // Reemplaza con el identificador correcto de tu modelo
        messages: [{ role: 'user', content: input }],
      };

      fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          console.log('Response received:', response);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log('Data received:', data);
          const botMessage = parseBotResponse(data.choices[0].message.content);
          setMessages((prevMessages) => [...prevMessages, botMessage]);
        })
        .catch((error) => {
          console.error('Error:', error);
        });

      setInput('');
    }
  };

  const parseBotResponse = (responseText) => {
    console.log('Parsing bot response:', responseText);
    let products = [];
    const productRegex = /Producto encontrado: (.+?) \((.+?)\)/g;
    let match;
    while ((match = productRegex.exec(responseText)) !== null) {
      products.push({ Codigo: match[2], Descripcion: match[1] });
    }
    console.log('Parsed products:', products);
    return products.length > 0
      ? { sender: 'bot', text: 'Tenemos los siguientes productos similares:', products }
      : { sender: 'bot', text: responseText };
  };

  const handleAddToCotizacion = (product) => {
    console.log('Adding product to cotizacion:', product);
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: 'bot', text: `Producto agregado a la cotización: ${product.Codigo} - ${product.Descripcion}` }
    ]);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    const newMessage = { sender: 'user', text: '', image: URL.createObjectURL(file) };
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    const formData = new FormData();
    formData.append('image', file);

    console.log('Uploading image:', file);
    fetch('http://localhost/backend/upload.php', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        console.log('Image upload response:', response);
        return response.json();
      })
      .then((data) => {
        console.log('Image upload data:', data);
        setMessages((prevMessages) => [...prevMessages, { sender: 'bot', text: `Processed image response: ${data.response}` }]);
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      });

    setImage(null);
  };

  const handleImageClick = (index) => {
    setCarouselIndex(index);
    setCarouselOpen(true);
  };

  const images = messages.filter((msg) => msg.image).map((msg) => msg.image);

  return (
    <div style={{ padding: '10px' }}>
      <CabeceraChatGPT />
      <MessageList
        messages={messages}
        handleImageClick={handleImageClick}
        handleAddToCotizacion={handleAddToCotizacion}
        scrollToBottom={scrollToBottom}
        chatEndRef={chatEndRef}
      />
      <ChatInput input={input} setInput={setInput} sendMessage={sendMessage} handleImageChange={handleImageChange} />
      {carouselOpen && (
        <ImageCarousel images={images} carouselIndex={carouselIndex} setCarouselIndex={setCarouselIndex} setCarouselOpen={setCarouselOpen} />
      )}
    </div>
  );
};

export default ChatGPT;
