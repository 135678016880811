import './HeaderLogin.css'
import icon_Email from '../../../img/icon_email.svg'
const HeaderLogin = () =>{
    return(
        <div className='HeaderLogin'>
            <ul>
                <li>
                    <a href="mailto:info@electroleg.com" rel="noopener noreferrer">
                        <img src={icon_Email} alt='Email' width='33px' height='33px'/>
                        <b>info@electroleg.com</b>
                    </a>
                </li>
            </ul>
        </div>
    )
}
export default HeaderLogin